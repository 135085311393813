<script lang="ts">
  import logoWordSrc from '$lib/assets/images/logo-word.png';
  import logoExcelSrc from '$lib/assets/images/logo-xcel.png';
  import ProgressBar from '$lib/components/ProgressBar.svelte';
  import Spinner from '$lib/components/Spinner.svelte';
  import { downloadFromURL } from '$lib/utils';
  import { localized, type Locale } from './localization';

  const NB_ENTITIES: number = 200;

  export let lang: Locale;

  $: ll = localized[lang];

  let isLoading = false;
  let nbDocsGenerated = 0;
  let estimatedRemainingMs: number | null = null;
  let success = false;

  function randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  function triggerFakeGeneration() {
    nbDocsGenerated = 0;
    estimatedRemainingMs = null;
    success = false;

    // Fake generation time between 2 and 4 secs
    const generationTimeMs = randomIntFromInterval(2 * 1000, 4 * 1000);
    estimatedRemainingMs = generationTimeMs;
    isLoading = true;

    const updateEverMs = 100;
    const increment = Math.ceil(
      (NB_ENTITIES / generationTimeMs) * updateEverMs,
    );

    function wrapUp(intervalID: any) {
      if (!success) {
        clearInterval(intervalID);
        isLoading = false;
        success = true;
        downloadFromURL('/try-it-now/pome Demo - Output.zip');
      }
    }

    const intervalID = setInterval(() => {
      if (nbDocsGenerated + increment < NB_ENTITIES)
        nbDocsGenerated += increment;
      else {
        wrapUp(intervalID);
      }
      if (estimatedRemainingMs !== null) estimatedRemainingMs -= updateEverMs;
    }, updateEverMs);

    setTimeout(() => {
      wrapUp(intervalID);
    }, generationTimeMs);
  }
</script>

<div class="mt-4 flex justify-center font-causten font-bold">
  <div class="flex w-9/12 max-w-4xl flex-col space-y-4 md:space-y-8">
    <div
      class="flex w-full flex-col space-y-2 rounded-xl bg-pome-dark-2 py-4 md:space-y-3 md:pb-5"
    >
      <div class="flex w-full">
        <div class="w-1/2 px-4">
          <h1 class="text-[20px] leading-none">{ll.template}</h1>
        </div>
        <div class="w-1/2 px-4">
          <h1 class="text-[20px] leading-none">{ll.datasheet}</h1>
        </div>
      </div>
      <div class="flex w-full">
        <a
          href="/try-it-now/pome Demo - Stock Plan Template.docx"
          download
          class="mx-4 flex w-1/2 cursor-pointer flex-col items-center space-y-2 rounded-xl bg-pome-light py-2 hover:opacity-90 md:flex-row md:space-x-4 md:p-4"
        >
          <img
            src={logoWordSrc}
            alt="Microsoft Word logo"
            class="h-16 md:h-20"
          />
          <div class="px-1 text-center text-sm text-pome-dark md:text-xl">
            Stock Plan Template.docx
          </div>
        </a>

        <a
          href="/try-it-now/pome Demo - Datasheet - 200 Entities.xlsx"
          download
          class="mx-4 flex w-1/2 cursor-pointer flex-col items-center space-y-2 rounded-xl bg-pome-light py-2 hover:opacity-90 md:flex-row md:space-x-4 md:p-4"
        >
          <img
            src={logoExcelSrc}
            alt="Microsoft Word logo"
            class="h-16 md:h-20"
          />
          <div class="px-1 text-center text-sm text-pome-dark md:text-xl">
            Datasheet - 200 Entities.xlsx
          </div>

          <!-- <a
              href="/try-it-now/Pome Demo - Datasheet - 200 Entities.xlsx"
              download
            ></a> -->
        </a>
      </div>
    </div>

    <div class="flex items-end space-x-5 font-bold">
      <div
        class="flex w-full flex-col items-end justify-center space-y-3 rounded-xl bg-pome-dark-2 px-4 py-4 md:flex-row md:space-y-0 md:py-5"
      >
        <div class="flex w-full flex-col space-y-3 md:w-1/2 md:pr-4">
          {#if isLoading}
            <ProgressBar
              total={NB_ENTITIES}
              current={nbDocsGenerated}
              {estimatedRemainingMs}
            />
          {/if}
          {#if success}
            <div
              id="compiler-div-success"
              class="pome-alert mb-1 bg-pome-alert-success p-3 text-xl"
            >
              {ll.documentsWereSuccessfullyGeneratedPrefix}{NB_ENTITIES}{ll.documentsWereSuccessfullyGeneratedSuffix}
            </div>
          {/if}
          <button
            type="submit"
            class="btn btn-primary flex justify-center"
            data-cy="compile-submit-all"
            on:click={triggerFakeGeneration}
            disabled={isLoading}
          >
            {#if !isLoading}
              <span
                class="plausible-event-name=Landing/ClickedDemoGenerate text-xl"
                >{ll.generateDocumentsPrefix}{NB_ENTITIES}{ll.generateDocumentsSuffix}</span
              >
            {:else}<Spinner />{/if}
          </button>
        </div>
        <div class="flex w-full flex-col space-y-3 md:w-1/2 md:pl-4">
          <a
            href="/auth/signup"
            class="btn btn-light flex justify-center hover:text-pome-red hover:opacity-90"
            data-cy="compile-submit-all"
          >
            <span class="text-xl">{ll.signUpForMore}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
