<script lang="ts">
  /**
   * Idea from: https://9elements.com/blog/pure-css-diagonal-layouts/
   */

  import { isValue } from '$lib/utils';

  /** Classes to apply to content wrapper. */
  let extraClasses: string = '';
  export { extraClasses as class };
  export let skewedExtraClasses: string = 'bg-black';
  export let degrees = -7;
  export let withTopMargin = false;
  export let withBottomMargin = false;
  /**
   * Set to true to add 1px to the top of the slant,
   * used to prevent a 1px gap between the slant and the content above it.
   */
  export let overlapTop = false;

  $: skewPaddingFactor = Math.tan((Math.PI / 180) * Math.abs(degrees));
  // Round to 6 decimal places
  $: skewPaddingFactorRounded =
    Math.round(skewPaddingFactor * 100_000) / 100_000;

  $: style = {
    /* eslint-disable @typescript-eslint/naming-convention */
    'padding-top': `calc(${skewPaddingFactorRounded} * 50%)`,
    'padding-bottom': `calc(${skewPaddingFactorRounded} * 50%)`,
    'margin-top': withTopMargin
      ? `calc(${skewPaddingFactorRounded} * 50%)`
      : null,
    'margin-bottom': withBottomMargin
      ? `calc(${skewPaddingFactorRounded} * 50%)`
      : null,
    /* eslint-enable @typescript-eslint/naming-convention */
  };
  $: styleString = Object.entries(style)
    .filter(([_, value]) => isValue(value))
    .map(([key, value]) => `${key}: ${value};`)
    .join(' ');
</script>

<div class="relative" role="presentation" style={styleString}>
  <!-- The slanted div -->
  <div
    aria-hidden="true"
    role="presentation"
    class="absolute bottom-0 left-0 right-0 top-0 z-0 transform {skewedExtraClasses}"
    class:top-0={!overlapTop}
    class:-top-[1px]={overlapTop}
    style="transform: skewY({degrees}deg);"
  />
  <!-- The content -->
  <div class="relative {extraClasses}" role="presentation">
    <slot />
  </div>
</div>
