<script lang="ts">
  export let id: `testimonial-${string}`;
</script>

<div
  {id}
  class="flex w-full flex-none snap-start flex-col justify-center gap-y-8 px-2
         md:flex-row md:gap-x-8 md:gap-y-0 md:px-6 xl:px-6"
>
  <div
    class="relative max-w-[60ch] flex-shrink text-[1.2rem] md:text-[1.375rem]"
  >
    <slot />
  </div>

  <div
    class="text-[1.2rem] opacity-60 md:w-max md:max-w-[20ch] md:flex-shrink-0 md:self-end md:text-right md:text-[1.375rem]"
  >
    <slot name="title" />
  </div>
</div>
