<script lang="ts">
  import type { LanguageLocalization, Locale } from '../localization';
  import TestimonialCell from './TestimonialCell.svelte';

  export let lang: Locale;
  export let ll: LanguageLocalization;

  let scrollContainer: HTMLElement | undefined;
  /** The previous testimonial element to scroll to, or null if there is none. */
  let previousTestimonialElement: HTMLElement | null = null;
  /** The next testimonial element to scroll to, or null if there is none. */
  let nextTestimonialElement: HTMLElement | null = null;

  /**
   * This function is called when the user scrolls the testimonials section.
   * It updates the values of previousTestimonialElement and nextTestimonialElement.
   */
  function handleScroll(event: { currentTarget: HTMLElement }) {
    let closestPreviousElement: HTMLElement | null = null;
    let distanceToClosestPreviousElement = Infinity;
    let closestNextElement: HTMLElement | null = null;
    let distanceToClosestNextElement = Infinity;
    const scrollLeft = Math.round(event.currentTarget.scrollLeft);
    for (const child of event.currentTarget.children) {
      if (child instanceof HTMLElement && child.id.startsWith('testimonial-')) {
        if (
          child.offsetLeft < scrollLeft &&
          scrollLeft - child.offsetLeft < distanceToClosestPreviousElement
        ) {
          closestPreviousElement = child;
          distanceToClosestPreviousElement = scrollLeft - child.offsetLeft;
        } else if (
          child.offsetLeft > scrollLeft &&
          child.offsetLeft - scrollLeft < distanceToClosestNextElement
        ) {
          closestNextElement = child;
          distanceToClosestNextElement = child.offsetLeft - scrollLeft;
        }
      }
    }

    previousTestimonialElement = closestPreviousElement ?? null;
    nextTestimonialElement = closestNextElement ?? null;
  }
  // When the scroll container is mounted, we need to trigger the scroll event
  // to set the initial values of previousTestimonialElement and nextTestimonialElement
  $: scrollContainer && handleScroll({ currentTarget: scrollContainer });
</script>

<section id="testimonials" class="w-full pt-10">
  <h2
    class="mb-6 px-2 text-center text-[2.8rem] font-semibold
           leading-tight md:mb-12 md:px-6 md:text-[3.625rem] xl:px-6 xl:text-[4.25rem]"
  >
    {ll.testimonialsTitle}
  </h2>

  <!-- Scroller -->
  <div
    bind:this={scrollContainer}
    class="flex snap-x snap-mandatory flex-row overflow-x-auto pb-4"
    on:scroll={handleScroll}
  >
    <!-- Testimonials -->
    <TestimonialCell id="testimonial-cichostepski">
      <span slot="title" class="flex flex-wrap gap-x-1 sm:block">
        <span>Benjamin Cichostepski,</span>
        <span>Partner at Orrick Paris Tech Studio</span>
      </span>

      {#if lang === 'fr'}
        <p>
          Avec pome, notre équipe peut <em
            >se concentrer sur ce qui compte le plus</em
          >.
        </p>
        <p>Je recommande fortement !</p>
      {:else}
        <!-- Default to english translation -->
        <p>
          With pome, our team is able to
          <em>focus on what matters most</em>.
        </p>
        <p>I strongly recommend!</p>
      {/if}
    </TestimonialCell>

    <TestimonialCell id="testimonial-lelievre">
      <span slot="title">
        <span>Mayeul Lelièvre,</span>
        <span>Associate</span>
      </span>

      {#if lang === 'fr'}
        <p>
          pome est un outil <em>intuitif</em> qui m’a permis d’individualiser un
          <em>grand nombre</em> de documents en <em>quelques minutes</em>. pome
          permet également d’adapter les documents en prenant en compte les
          <em>spécificités</em> des documents dans le processus d’individualisation.
        </p>
        <p>Gain de temps et de sommeil, je recommande fortement pome.</p>
      {:else}
        <p>
          pome is an <em>intuitive</em> platform which enabled me to
          individualize <em>large number</em> of documents in a
          <em>few minutes</em>. pome also enables you to adapt them to take into
          account <em>special characteristics</em> of the documents in the individualization
          process.
        </p>
        <p>Time and sleep saver, I strongly recommend pome.</p>
      {/if}
    </TestimonialCell>

    <TestimonialCell id="testimonial-gilioli">
      <span slot="title">
        <span>Juliette Gilioli,</span>
        <span>Intern</span>
      </span>

      {#if lang === 'fr'}
        <p>
          Je recommande vivement pome. L’outil est très <em>utile</em> et
          <em>intuitif</em>. Je l’utilise pour dupliquer des
          <em>formulaires de transfert de titres</em>, des
          <em>formulaires fiscaux</em> et des <em>procurations</em>. Il est
          possible, depuis le même template, d’utiliser des
          <em>formulations différentes</em> (par exemple, une formulation spécifique
          pour les personnes physiques et une autre pour les sociétés). Grâce à pome
          je gagne beaucoup de temps, la préparation de la documentation de closing
          est facilitée.
        </p>
      {:else}
        <p>
          I highly recommend pome. The tool is very <em>helpful</em> and
          <em>intuitive</em>. I use it to duplicate
          <em>share transfer forms</em>,
          <em>tax forms</em> and <em>powers of attorney</em>. It is possible,
          with the same template, to duplicate different wordings (for instance,
          a specific wording for physical persons and another one for the
          companies). Thanks to pome I save a lot of time, the preparation of
          the closing documentation is facilitated.
        </p>
      {/if}
    </TestimonialCell>

    <TestimonialCell id="testimonial-alexander">
      <span slot="title">
        <span>Julie Alexander,</span>
        <span>Paralegal</span>
      </span>

      {#if lang === 'fr'}
        <p>
          pome est un <em>gain de temps</em> considérable dans notre travail
          <em>au quotidien</em>, et nous sauve la vie lors des rushs de fin de
          soirée ! L’outil est <em>intuitif</em> et permet de
          <em>facilement</em> éditer toutes sortes de documents contractuels et corporate.
          Je recommande vivement pome.
        </p>
      {:else}
        <p>
          pome is a great <em>time saver</em> in our <em>daily</em> work, and a
          life saver during late night closing rushes! The tool is
          <em>intuitive</em> and can <em>easily be used</em>
          to edit all sorts of contractual and corporate documents. I highly recommend
          pome.
        </p>
      {/if}
    </TestimonialCell>
  </div>

  <!-- Previous and next buttons -->
  <div class="flex justify-center gap-x-8 text-3xl">
    <!-- svelte-ignore a11y-no-redundant-roles -->
    <a
      role="link"
      href={previousTestimonialElement
        ? `#${previousTestimonialElement.id}`
        : '#testimonials'}
      class="inline-block select-none p-3 transition-all
             active:text-pome-red-hover hoverable:hover:text-pome-red-hover"
      class:opacity-100={!!previousTestimonialElement}
      class:opacity-20={!previousTestimonialElement}
      inert={!previousTestimonialElement}
      aria-disabled={!previousTestimonialElement}
      on:click|preventDefault={() =>
        previousTestimonialElement?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        })}>←</a
    >
    <!-- svelte-ignore a11y-no-redundant-roles -->
    <a
      role="link"
      href={nextTestimonialElement
        ? `#${nextTestimonialElement.id}`
        : '#testimonials'}
      class="inline-block select-none p-3 transition-all
             active:text-pome-red-hover hoverable:hover:text-pome-red-hover"
      class:opacity-100={!!nextTestimonialElement}
      class:opacity-20={!nextTestimonialElement}
      inert={!nextTestimonialElement}
      aria-disabled={!previousTestimonialElement}
      on:click|preventDefault={() =>
        nextTestimonialElement?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        })}>→</a
    >
  </div>
</section>

<style lang="postcss">
  em {
    @apply font-semibold not-italic text-pome-red-hover;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  section {
    scroll-behavior: smooth;
  }
</style>
